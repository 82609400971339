<template>
  <div class="w-full">
    <form
      @submit.prevent="submitBusinessDetails"
      v-if="business.detail != undefined"
    >
      <app-form-input
        id="business.detail.company_name"
        name="business.detail.company_name"
        type="text"
        :placeholder="$t('business.detail.company_placeholder')"
        :labelText="$t('business.detail.company_label')"
        class="mb-5"
        v-model="business.detail.company_name"
        :errorMessage="errors.company_name"
        :disabled="editMode"
      />

      <app-form-input
        id="business.detail.company_reg_no"
        name="business.detail.company_reg_no"
        type="text"
        :placeholder="$t('business.detail.company_reg_placeholder')"
        :labelText="$t('business.detail.company_reg_no_label')"
        class="w-full mb-5"
        v-model="business.detail.company_reg_no"
        :errorMessage="errors.reg_no"
        :disabled="editMode"
      />

      <app-form-input
        id="business.detail.tax_id"
        name="business.detail.tax_id"
        type="text"
        :placeholder="$t('business.detail.tax_id')"
        :labelText="$t('business.detail.tax_id')"
        class="w-full mb-5"
        v-model="business.detail.tax_id"
        :errorMessage="errors.tax_id"
        :disabled="editMode"
      />

      <app-form-company-annual-turnover
        :placeholder="$t('business.detail.annual_turnover_placeholder')"
        class="w-full mb-5"
        :country_id="business.country?.id ?? 0"
        v-model="business.detail.company_annual_turnover"
        :errorMessage="errors.company_annual_turnover_id"
        :showLabel="true"
        :allowFilter="false"
        :disabled="editMode"
      />

      <app-form-input
        id="business.detail.business_activity"
        name="business.detail.business_activity"
        type="text"
        :labelText="$t('business.detail.activity_label')"
        class="w-full mb-5"
        v-model="business.detail.business_activity"
        :errorMessage="errors.business_activity"
        :disabled="editMode"
      />

      <!-- Hide for temporary (for revisit https://app.clickup.com/t/2e0dnyj)

        <app-form-input
        id="business.detail.address_1"
        name="business.detail.address_1"
        class="w-full"
        type="text"
        :placeholder="$t('general.address.line_1')"
        :labelText="$t('business.structure.address_label')"
        v-model="business.detail.address_1"
        :errorMessage="errors.address_1"
        :disabled="editMode"
      />

      <app-form-input
        id="business.detail.address_2"
        name="business.detail.address_2"
        class="w-full"
        type="text"
        :placeholder="$t('general.address.line_2')"
        v-model="business.detail.address_2"
        :errorMessage="errors.address_2"
        :disabled="editMode"
      />

      <app-form-input
        id="business.detail.postcode"
        name="business.detail.postcode"
        class="w-full"
        type="text"
        :placeholder="$t('general.address.postcode')"
        v-model="business.detail.postcode"
        :errorMessage="errors.postcode"
        :disabled="editMode"
      />

      <app-form-input
        id="business.detail.city"
        name="business.detail.city"
        class="w-full"
        type="text"
        :placeholder="$t('general.address.city')"
        v-model="business.detail.city"
        :errorMessage="errors.city"
        :disabled="editMode"
      />

      <app-form-state
        :showLabel="false"
        class="w-full mb-5"
        :placeholder="$t('general.address.state')"
        :country_id="business.country?.id ?? 0"
        v-model="business.detail.state"
        :disabled="editMode"
      /> -->

      <app-form-telephone-no
        name="business.detail.phone_no"
        :labelText="$t('business.detail.phone_no_label')"
        class="w-full mb-5"
        v-model="business.detail.phone_no"
        :errorMessage="errors.phone_no"
        :disabled="editMode"
      />

      <app-form-industries
        :labelText="$t('business.detail.industry_label')"
        :placeholder="$t('business.detail.industry_placeholder')"
        class="w-full mb-5"
        :country_id="business.country?.id ?? 0"
        v-model="business.detail.industry"
        :errorMessage="errors.industry_id"
        :showLabel="true"
        :allowFilter="false"
        :disabled="editMode"
      />

      <app-form-input
        id="business.detail.website"
        name="business.detail.website"
        class="w-full mb-5"
        type="text"
        :labelText="$t('business.detail.website_label')"
        :placeholder="$t('business.detail.website_placeholder')"
        v-model="business.detail.website"
        :errorMessage="errors.website"
        :disabled="editMode"
      />

      <app-form-textarea
        id="business.detail.description"
        name="business.detail.description"
        type="text"
        class="mb-5"
        :labelText="$t('business.detail.product_desc_label')"
        v-model="business.detail.description"
        :errorMessage="errors.description"
        :disabled="editMode"
      />

      <app-button
        type="submit"
        v-if="!editMode"
        :showf70Icon="false"
        class="mt-3 w-full"
        :disabled="!hasChanged"
        :loading="loading"
      >
        {{ $t("general.save") }}
      </app-button>

      <app-button-outline class="mt-3 w-full" @onClick="editAction">
        {{ editMode ? $t("general.edit_information") : $t("general.cancel") }}
      </app-button-outline>
    </form>
  </div>
</template>

<script>
export default {
  props: ["menu_action", "edit_mode"],

  data() {
    return {
      hasChanged: false,
      emptyErrors: {
        company_name: [],
        company_reg_no: [],
        business_activity: [],
        address_1: [],
        address_2: [],
        postcode: [],
        city_id: [],
        state_id: [],
        phone_no: [],
        industry_id: [],
        website: [],
        description: [],
      },
    };
  },

  watch: {
    business: {
      handler: function () {
        this.hasChanged = true;
      },
      deep: true,
    },
  },

  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    editMode: {
      get() {
        return this.edit_mode;
      },
      set(v) {
        this.$emit("update:edit_mode", v);
      },
    },
  },

  methods: {
    async submitBusinessDetails() {
      this.resetState();

      const businessDetails = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessDetail",
        this.business.detail
      );

      if (businessDetails != null) {
        this.hasChanged = false;
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          3000
        );
      }
    },
    editAction() {
      this.editMode = !this.editMode;

      if (this.editMode == false) {
        this.$store.dispatch(
          "businessStore/getBusinessById",
          this.$route.params.business_id
        );
      }
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>
